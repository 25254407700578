import { Row } from '@shipt/design-system-layouts'
import { useCurrentRouteDataContext } from '@/features/shared/context/RouteDataContext'
import { ButtonLink } from '@/features/shared/elements/Link/Buttons'
import { useUtmUrl } from '@/features/shared/hooks/useUtmUrl'
import { routes } from '@shared/constants/routes'
import { trackElementClicked } from '@/features/shared/analytics/element'
import { useSession } from '@/features/authentication/utils/authentication/hooks/useSession'
import { ClientOnly } from '@/features/shared/components/ClientOnly'
import { type SizeOption } from '@shipt/design-system-themes'
import { useRouter } from 'next/router'

type Props = { linkSize?: Extract<SizeOption, 'sm' | 'md'> }

// this needs to be wrapped in ClientOnly because it uses useSession
// which has logic that should only run on the client
export const HeaderUserLinks = (props: Props) => (
  <ClientOnly>
    <HeaderUserLinksContent {...props} />
  </ClientOnly>
)

const HeaderUserLinksContent = ({ linkSize = 'md' }: Props) => {
  const { routeName } = useCurrentRouteDataContext() ?? {}
  const { asPath, query } = useRouter()

  const isRoundelLP = asPath.includes(`${routes.LP.url}/promotions`)
  const getPromotionRoute = () => {
    const promotionId = Array.isArray(query.slug) ? query.slug.at(-1) : ''
    return promotionId
      ? `${routes.FEATURED_PROMOTIONS.url}/${promotionId}?promotion_category_id=${promotionId}`
      : ''
  }
  const loginUrl = useUtmUrl(
    isRoundelLP
      ? `${routes.LOGIN.url}?next=${getPromotionRoute()}`
      : routes.LOGIN.url
  )
  const signupUrl = useUtmUrl(
    `${routes.SIGN_UP.url}?from=${routeName}${
      isRoundelLP ? `&next=${encodeURIComponent(asPath)}` : ''
    }`
  )
  const { isSessionLoading, isSessionValid } = useSession()

  const handleOnClickLink = (
    content: 'sign_in' | 'sign_up' | 'continue with account'
  ) =>
    trackElementClicked({
      type: 'link',
      content,
      display_sublocation: 'header',
    })

  if (isSessionLoading) return null

  return (
    <Row spacing="lg" align="center">
      {isSessionValid ? (
        <ButtonLink
          href={routes.GLOBAL_HOMEPAGE.url}
          onClick={() => handleOnClickLink('continue with account')}
          variant="secondary"
          size={linkSize}
        >
          Continue with account
        </ButtonLink>
      ) : (
        <>
          <ButtonLink
            href={loginUrl}
            onClick={() => handleOnClickLink('sign_in')}
            variant="secondary"
            size={linkSize}
          >
            Sign in
          </ButtonLink>
          <ButtonLink
            href={signupUrl}
            onClick={() => handleOnClickLink('sign_up')}
            size={linkSize}
          >
            Sign up
          </ButtonLink>
        </>
      )}
    </Row>
  )
}
